<template>
  <div v-html="html"></div>
</template>

<script>
import service from './api';
export default {
  data() {
    return {
      html: ''
    };
  },
  methods: {
    getPay() {
      let { id = null, type = 1 } = JSON.parse(sessionStorage.getItem('pcPay'));
      return service.alipay({ id, type }).then(res => {
        this.html = res;
        this.$nextTick(() => {
          document.forms[0].submit();
        });
      });
    }
  },
  mounted() {
    this.getPay();
  }
};
</script>

<style lang="scss" scoped>
</style>
